import find from 'lodash/find';
import get from 'lodash/get';

const breakpointName = (breakpoint = {}) =>
  breakpoint.name === 'default' ? 'xl' : breakpoint.name;

const hasBreakpoint = (classes, name) =>
  classes.some(className => className.startsWith(`col-${name}`));

export const hasSizing = data => !!data.cqResponsive && !!data.cqResponsive.deviceBreakpoints;

export const progressiveGridSizing = (classes, sizing, base = 12, setDefaultValue = false) => {
  const clazzes = Array.isArray(classes) ? classes : [classes];

  get(sizing, 'deviceBreakpoints', []).forEach(breakpoint => {
    const { width = '12', offset = '0', visible = true } = breakpoint;

    if (!visible) {
      return clazzes.push(`col-${breakpointName(breakpoint)}-hide`);
    }

    const sanitizedOffset = parseInt(offset, 10) || 0;
    clazzes.push(`col-${breakpointName(breakpoint)}-${width}of${base}`);
    return clazzes.push(`col-${breakpointName(breakpoint)}-offset-${sanitizedOffset}of${base}`);
  });

  // add default value for all up-to specified breakpoints
  // e.g. if we only have md specified, add default value for xs & sm
  const defaultValues = [];
  if (setDefaultValue) {
    // eslint-disable-next-line
    for (const breakpoint of ['xs', 'sm', 'md', 'lg', 'xl']) {
      // if this breakpoint has a width specified do not add the default, ie. break the loop
      if (hasBreakpoint(clazzes, breakpoint)) break;
      defaultValues.push(`col-${breakpoint}-12of12`);
      defaultValues.push(`col-${breakpoint}-offset-0of12`);
    }
  }

  return [...defaultValues, ...clazzes];
};

export const isBreakpointLargerThanX = (cqResponsive, name, largerThan) => {
  const deviceBreakpoints = get(cqResponsive, 'deviceBreakpoints', []);
  const breakpointSize = get(find(deviceBreakpoints, { name }), 'width');
  if (!breakpointSize) return false;

  return Number(breakpointSize) > largerThan;
};
