import React, { Fragment, memo } from 'react';

import ProductGroupTitle from 'components/ProductGrid/Group/Title';
import BuilderGroupTitle from 'components/ProductGrid/Group/BuilderTitle';
import ProductList from 'components/ProductList';
import ProductGridItems from 'components/ProductGrid/ProductGridItems';

import { ProductGroupProps } from './types';

const ProductGroup = memo(
  ({
    isBuilderPage = false,
    listId,
    products = [],
    contextProducts = [],
    searchType = null,
    taxonomyLevel = null,
    title,
  }: ProductGroupProps) => (
    <Fragment>
      {isBuilderPage ? (
        <BuilderGroupTitle title={title} />
      ) : (
        <ProductGroupTitle withBorder title={title} />
      )}
      <ProductList withContainer={false}>
        <ProductGridItems
          items={products}
          isBuilderPage={isBuilderPage}
          listId={listId}
          contextItems={contextProducts}
          searchType={searchType}
          taxonomyLevel={taxonomyLevel}
        />
      </ProductList>
    </Fragment>
  ),
);

ProductGroup.displayName = 'ProductGroup';

export default ProductGroup;
