import { getPDPUrl } from 'utils/product';
import urls from 'constants/urls';
import { Product, Link } from '.';

const PARAM_SEPARATOR = '%2C';
const LINK_TEXT = 'Shop now';

function getBannerUrl(url: string, products: Product[]) {
  if (url) return url;

  if (products.length === 1) {
    const [product] = products;
    const { id, name } = product;

    return getPDPUrl(id, name) || '';
  }

  const lineNumberParams = products
    .map(({ lineNumber }: { lineNumber: string }) => lineNumber)
    .join(PARAM_SEPARATOR);

  return `${urls.citrusAdsBuylistPage}/${lineNumberParams}`;
}

export function generateBannerLink(citrusLink = '', products: Product[]): Link {
  if (!products?.length) return {};

  const url = getBannerUrl(citrusLink, products);

  // Handle edge case where link hasn't been generated as expected.
  if (!url) return {};

  return {
    url,
    text: LINK_TEXT,
    altText: LINK_TEXT,
  };
}
