import { useState, useEffect } from 'react';
import { usePrevious } from 'hooks';

export const useLoading = ({ orderId, loading, onDataLoad }) => {
  const [loaded, setLoaded] = useState(undefined);

  const previousLoading = usePrevious(loading);

  useEffect(() => {
    if (onDataLoad && loaded) onDataLoad();
  }, [loaded, onDataLoad]);

  useEffect(() => {
    if (previousLoading === loading) return;
    setLoaded(previousLoading && !loading);
  }, [loading, previousLoading]);

  return {
    showSpinner: orderId && loading,
  };
};
