import React, { memo, ReactNode } from 'react';
import classNames from 'classnames';

import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';

import styles from 'components/ProductGrid/ProductGrid.scss';

interface ProductGroupTitleProps {
  title?: string | ReactNode;
  withBorder: boolean;
}

const ProductGroupTitle = memo(({ title, withBorder = true }: ProductGroupTitleProps) => {
  if (title === undefined) return null;
  return (
    <header
      aria-hidden
      className={classNames(styles.productGroupTitle, {
        [styles.withBorder]: withBorder,
      })}
    >
      <Typography element="h3" styleAs="sectionHeading" data-testid="product-group-title" noMargins>
        {title}
      </Typography>
    </header>
  );
});

ProductGroupTitle.displayName = 'ProductGroupTitle';

export default ProductGroupTitle;
