import { connect } from 'react-redux';
import { getMealDealGroupByGroupId } from 'redux/modules/meal-deals/selectors';
import { getCategoryById } from 'redux/modules/taxonomy/selectors';
import { SEARCH_TYPE as MEAL_DEAL_SEARCH_TYPE } from 'components/MealDealPage/constants';
import { SEARCH_TYPE as BUNDLE_SEARCH_TYPE } from 'components/BundleBuilderPage/constants';

import ProductGroup from './ProductGroup';

export default connect((state, { categoryId, searchType }) => {
  const groupSelector = () => {
    switch (searchType) {
      case MEAL_DEAL_SEARCH_TYPE:
        return getMealDealGroupByGroupId(state, categoryId);
      case BUNDLE_SEARCH_TYPE:
        return { name: categoryId };
      default:
        return getCategoryById(state, categoryId);
    }
  };

  const group = groupSelector();

  return {
    title: group?.name,
  };
})(ProductGroup);
