import React, { memo } from 'react';

import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';

import styles from 'components/ProductGrid/ProductGrid.scss';

interface BuilderGroupTitleProps {
  title?: string;
}
const BuilderGroupTitle = memo(({ title }: BuilderGroupTitleProps) => {
  if (!title) return null;

  return (
    <header aria-hidden className={styles.productGroupTitle}>
      <div className={styles.builderTitleContainer} id={title}>
        <div className={styles.line} />
        <Typography
          element="h2"
          data-testid="product-group-title"
          styleAs="sectionHeadingSmall"
          className={styles.builderTitle}
        >
          {title}
        </Typography>
        <div className={styles.line} />
      </div>
    </header>
  );
});

BuilderGroupTitle.displayName = 'ProductGroupTitle';

export default BuilderGroupTitle;
