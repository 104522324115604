import React from 'react';

import TradingCell from 'content/components/TradingCellWrapper';
import { progressiveGridSizing } from 'utils/gridUtils';
import { TRADING_CELL } from '@johnlewispartnership/wtr-content-component-library/dist/component-library/constants/cmsResourceTypes';
import { CITRUS_AD } from 'constants/analytics';
import { generateBannerLink } from './generate-banner-link';

interface DeviceBreakpoints {
  width: string;
  name: string;
  auto: boolean;
  layout: string;
  visible: boolean;
}

export interface BannerLink {
  url: string;
  text: string;
  altText: string;
}

export type Link = BannerLink | Record<PropertyKey, never>;

export interface Product {
  id: string;
  lineNumber: string;
  name: string;
}

interface Banner {
  id: string;
  name: string;
  type: string;
  gridPosition: string;
  adTechPosition: string;
  image: { src: string; altText: string; mode: string };
  textArea: {
    heading: string;
    color: string;
    subheading: string;
    offerHeading: string;
    link: Link;
  };
  products: Product[];
  marketingBadge?: string;
  cqResponsive: {
    deviceBreakpoints: DeviceBreakpoints[];
  };
}

interface ComponentProps {
  __typename: string;
  banner: Banner;
}

export interface CitrusAdsBannerProps {
  component?: ComponentProps;
  position?: string;
  index?: number;
}

const CitrusAdsBanner = ({ component, position, index }: CitrusAdsBannerProps) => {
  if (!component) {
    return null;
  }

  const {
    banner: {
      textArea,
      id,
      name,
      image: { src, altText },
      marketingBadge,
      products,
      cqResponsive,
    },
  } = component;

  const formattedProducts = products?.map(product => ({
    id: product.id,
  }));

  const cqResponsiveClasses = progressiveGridSizing([], cqResponsive, 12, true);

  let { link } = textArea;
  link = generateBannerLink(link?.url, products);

  const analyticsTags = {
    'data-analytics-id': id,
    'data-analytics-name': name,
    'data-analytics-position': position,
  };

  const tradingCellProps = {
    ...component,
    externalImageSrc: true,
    products: formattedProducts,
    index,
    isCitrusAd: true,
    textArea: {
      ...textArea,
      link,
    },
    position,
    componentId: id,
    cqResponsive,
    cqResponsiveClasses,
    defaultNumOfCells: 'twoCells',
    resourceType: TRADING_CELL,
    icons: {
      messageIcon: {
        display: !!marketingBadge,
        data: {
          message: marketingBadge,
        },
      },
      offerRoundel: {
        display: !!textArea?.offerHeading,
        image: {
          altText: 'Offers',
          src: '/content/dam/waitrosegroceriescms/icons/offerroundels/offers.png',
        },
      },
    },
    image: {
      landscapeImage: { src, altText, assetMetadataHeight: '986', assetMetadataWidth: '1500' },
    },
    analyticsTags,
    analytics: { promoName: CITRUS_AD },
  };

  return <TradingCell {...tradingCellProps} />;
};

export default CitrusAdsBanner;
